<template>
  <a-tabs v-model:activeKey="activeKey">
    <a-tab-pane key="1" tab="SaaS">
      <table-page :config="config1" />
    </a-tab-pane>
    <a-tab-pane key="2" tab="CRM">
      <table-page :config="config2" />
    </a-tab-pane>
  </a-tabs>
</template>

<script>
import TablePage from "@/components/TablePage";

export default {
  name: 'SaasAdminRebateRulesReviewDetail',
  components: { TablePage },
  props: ['selectedRows'],
  data() {
    return {
      activeKey: '1',
      config1: {
        dataUrl: `/satisfaction/survey/list?systemType=SaaS`,
        otherInfos: {
          renderHtml: (val)=>{
            return `<p style="font-size: 20px; margin-bottom: 20px;">净推荐值：${val.num}</p>`
          }
        },
        filter: {
          // initUrl: '/operationLog/listFunction?menuCode=0',
          controls: [
            {
              key: 'time',
              label: '提交时间',
              type: 'dateRange'
            },
          ]
        },
        columns: [
          {
            key: 'score',
            title: '评分',
            width: '130px',
          },
          {
            key: 'remark',
            title: '建议',
            width: '250px',
            ellipsis: true
          },
          {
            key: 'employeeName',
            title: '提交账号',
            width: '130px',
          },
          {
            key: 'createdDate',
            title: '提交时间',
            width: '130px',
            type: 'date',
          },
        ],
        /* 表格操作 */
        actions: [
        ],
        /* 表格排序 */
        sorter: {
          /* 可排序列 */
          sortableColumns: [''],
          /* 默认排序 */
          defaultSort: ''
        },
        rowActions: [
        ]
      },
      config2: {
        dataUrl: `/satisfaction/survey/list?systemType=CRM`,
        otherInfos: {
          renderHtml: (val)=>{
            return `<p style="font-size: 20px; margin-bottom: 20px;">净推荐值：${val.num}</p>`
          }
        },
        filter: {
          initUrl: '/satisfaction/survey/org/get',
          controls: [
            {
              key: 'time',
              label: '提交时间',
              type: 'dateRange'
            },
            {
              key: 'orgId',
              label: '机构',
              type: 'souSelect',
              width: '260',
              config: {
                options: 'institutionsList'
              }
            }
          ]
        },
        columns: [
          {
            key: 'score',
            title: '评分',
            width: '130px',
          },
          {
            key: 'remark',
            title: '建议',
            width: '250px',
            ellipsis: true
          },
          {
            key: 'orgName',
            title: '提交机构',
            width: '130px',
          },
          {
            key: 'employeeName',
            title: '提交账号',
            width: '130px',
          },
          {
            key: 'createdDate',
            title: '提交时间',
            width: '130px',
            type: 'date',
          },
        ],
        /* 表格操作 */
        actions: [
        ],
        /* 表格排序 */
        sorter: {
          /* 可排序列 */
          sortableColumns: [''],
          /* 默认排序 */
          defaultSort: ''
        },
        rowActions: [
        ]
      },
    };
  },
  mounted() {
  },
  methods: {
  }

};
</script>

<style lang="less" scoped></style>